import { useDonatorCreateMutation } from "@hooks/data"
import { useTranslations } from "@hooks/translation"
import moment from "moment"
import React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { ButtonSubmit, IconButton } from ".."
import { AssociationCreationPersonalizedStyle } from "./AssociationCreationPersonalizedStyle"
import { CreationCryptoAddress } from "./CreationCryptoAddress"
import { CreationDonationForm } from "./CreationDonationForm"
import { CreationDonatorForm } from "./CreationDonatorForm"
import { CreationSaveSuspense } from "./CreationSaveSuspense"
import { CreationWalletForm } from "./CreationWalletForm"
import { useAssociationDonationSubmit } from "./hooks/useAssociationDonationSubmit"
import { useCreationFormData } from "./hooks/useCreationFormData"

export const AssociationCreationFormWizard = (props: AssociationCreationFormWizardProps) => {

    const [
        CONTINUE
    ] = useTranslations(i18n)
    const {
        submit,
        creationMutation,
        currentPayment,
        isError,
        isLoading,
        isSuccess,
        isExpired,
        paymentResult,
        account
    } = useAssociationDonationSubmit()
    const [waitScreen, setWaitScreen] = useState(false)
    const [step, setStep] = useState(currentPayment ? 2 : 0)
    const { formData, isValid } = useCreationFormData({}, requireds[step])

    const handleBackClick = useCallback(() => {
        setStep((v) => {
            if (v === 0) {
                return 0
            }
            return v - 1
        })
    }, [])

    useEffect(() => {
        if (creationMutation.isSuccess) {
            if (account) {
                setStep(3)
                setWaitScreen(true)
                return;
            }
            setStep(2)
        }
    }, [creationMutation, account])

    const handleNextClick = () => {
        if (step > 2 && !isValid()) {
            return;
        }
        if (step === 1) {
            submit(formData)
            return;
        }

        if (step === 2) {
            window.open(currentPayment?.coinhouseData.urlPayment, '_blank')
            setWaitScreen(true)
            return;
        }

        if (isValid()) {
            setStep((v) => v + 1)
        }
    }


    if (waitScreen) {
        return (
            <div className="h-full bg-secondary">
                <div className="h-screen flex justify-center items-center">
                    <CreationSaveSuspense
                        amont={creationMutation.variables?.amount || currentPayment?.coinhouseData.order.amount}
                        cryptoCurrency={creationMutation.variables?.crypto_coin || currentPayment?.coinhouseData.order.currency}
                        isError={isError}
                        isLoading={isLoading}
                        isSuccess={isSuccess}
                        onIconClick={() => {
                            if (!isExpired && paymentResult?.message === 'wait') {
                                setStep(2)
                                return;
                            }
                            window.location.reload()
                        }}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-6 w-full pt-32 pb-5 py-7 px-5 min-h-screen">
            <div>
                <IconButton
                    disabled={[0, 2].includes(step)}
                    icon={<img src="/assets/img/icons/arrow-left-circle.svg" />}
                    onClick={handleBackClick}
                />
            </div>
            <div className="association-donation-form">
                <div className="">
                    {
                        step === 0 &&
                        <CreationDonatorForm />
                    }

                    {
                        step === 1 &&
                        <div className="flex flex-col gap-20">
                            <CreationWalletForm />
                            <CreationDonationForm />
                        </div>
                    }

                    {
                        step === 2 &&
                        <div className="flex flex-col gap-20">
                            <CreationCryptoAddress
                                {...(currentPayment)!}
                                hideButton
                            />

                        </div>
                    }
                </div>
                <div className="mt-5">
                    <ButtonSubmit
                        isSubmit={creationMutation.isLoading}
                        className="btn-primary w-full justify-center"
                        style={{justifyContent: 'center'}}
                        onClick={handleNextClick}>
                        {CONTINUE}
                    </ButtonSubmit>
                </div>
            </div>
            <AssociationCreationPersonalizedStyle />
        </div>
    )
}

export type AssociationCreationFormWizardProps = {
    
}

const requireds = [
    [
        'first_name',
        'last_name',
        'title',
        'address.address',
        'address.postal_code',
        'address.city',
        'dob',
        'nationality',
        'phone',
        'country_code',
        'email',
    ],
    [
        'amount',
        'crypto_coin',
        'wallet_address',
    ]
]

const i18n = [
    "Vadato.Donators.Create.Continue.Button.Label",
]

