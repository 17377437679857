import { AppRoot, MainLogo } from '@vadato/component';
import { MetaMaskProvider } from 'metamask-react';
import React, { useEffect, useState } from 'react';
import { VadatoRoutes } from './page/VadatoRoutes'

const App: React.FC = () => {


    return (
        <MetaMaskProvider>
            <AppRoot
                routes={VadatoRoutes}
                suspenseFallback={<MainLogo img={'/img/logo.svg'} />}
            />
        </MetaMaskProvider>
    );
}

export default App;

