import { AppRouteProps } from '@vadato/component'
import React from 'react'
import { HomeAccessLayer } from './containers/HomeAccessLayer'

const Home = React.lazy(() => import('./containers/Home'))

export const HomeRoutes: Array<AppRouteProps> = [
    { 
        path: '', 
        element: <HomeAccessLayer />,
        children: [
            { 
                path: '', 
                element: <Home />,
            },
            process.env.REACT_APP_ENABLE_DONATOR_WITH_ASSOCIATION_ID === 'true' ? { 
                path: '/:associationId', 
                element: <Home />,
            } : {},
        ]
    }
]
