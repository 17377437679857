
import { Outlet } from "react-router-dom";
import "./css/mainLayout.css";
import { GenericSuspense } from "@vadato/component";
import { Footer } from "./Footer";


const MainLayout = () => {

    return (
        <div className="">
            <main className="">
                <GenericSuspense>
                    <Outlet />
                </GenericSuspense>
            </main>
        </div>
    );
};

export default MainLayout;
