import { GenericSuspense, useTranslations } from "@vadato/component"
import { Outlet } from "react-router-dom"

export const HomeAccessLayer = () => {

    const [
        ERROR_MESSAGE,
        RETRY
    ] = useTranslations(i18n)

    return (
        <GenericSuspense
            GenericSuspenseErrorProps={{
                message: ERROR_MESSAGE,
                retry: RETRY
            }}>
            <Outlet />
        </GenericSuspense>
    )
}

const i18n = [
    'Vadato.Loading.Error.Message',
    'Vadato.Loading.Error.Retry.Label'
]
